import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactElement } from 'react';

interface IJFModal {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactElement;
  modalStyle?: object;
  title?: string;
  closable?: boolean;
}

const style = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 'calc(100% - 100px)',
    backgroundColor: 'white',
    border: '1px solid #DBE4F3',
    boxShadow: '0 4 21 0 rgba(86, 111, 149, 0.12)',
    borderRadius: '17px',
  },
  modalHeader: {
    alignItems: 'center',
    padding: '20px 30px',
    marginBottom: 1,
  },
  modalTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#515660',
  },
  closeButton: {
    height: '60px',
    width: '60px',
    borderRadius: '50%',
    background: '#FFFFFF',
    border: '1px solid #DBE4F3',
    boxShadow: '0px 4px 21px rgba(86, 111, 149, 0.12)',
  },
  modalContent: {
    padding: '0px 30px 20px 30px',
  }
};

const JFModal = ({ isOpen, onClose, children, modalStyle = {}, title = '', closable = true, ...properties}: IJFModal) => {
  return (
    <Modal
      open={isOpen}
      onClose={!!closable ? onClose : undefined}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...properties}
    >
      <Box
        sx={[style.modal, modalStyle]}
      >
        {title &&
          <Grid container sx={style.modalHeader}>
            <Grid item xs={closable ? 10 : 12}>
              <Typography sx={style.modalTitle}>{title}</Typography>
            </Grid>
            {closable && (
              <Grid item container xs={2} justifyContent="flex-end">
                <IconButton sx={style.closeButton} size="small" onClick={onClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
              )
            }
          </Grid>
        }
        <Grid container sx={style.modalContent}>
          {children}
        </Grid>
      </Box>
    </Modal>
  );
};

export default JFModal;
