import { styled } from '@mui/material';
import { JFProfileImageProps } from './JFProfileImage.types';

export const JFProfileImage = styled('img')<JFProfileImageProps>(
  ({
    hasSrc,
    size,
    borderWidth = 3,
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 40,
    marginRight = 40,
    objectFit = 'contain',
    backgroundColor = '#FFF'
  }) => ({
    backgroundColor,
    borderColor: hasSrc ? '#FFF' : '#000',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth,
    height: size,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    objectFit,
    width: size
  })
);
