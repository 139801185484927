import { styled } from '@mui/material';
import { JFBannerProps } from './JFBanner.types';

export const JFBanner = styled('img')<JFBannerProps>(
  ({ hasSrc, borderRadius = 17, borderRadiusTopOnly = false }) => ({
    width: '100%',
    objectFit: 'cover',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    borderBottomLeftRadius: borderRadiusTopOnly ? 0 : borderRadius,
    borderBottomRightRadius: borderRadiusTopOnly ? 0 : borderRadius,
    border: hasSrc ? 'none' : '2px solid #BCBFB7'
  })
);
