import { Switch, Route } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { jfTheme } from '../../../styles/jf.theme'
import { PublicAppBar } from './PublicAppBar';
import { NotFoundPage } from '../../../shared/util/NotFoundPage';
import { CustomPublicProfile } from '../../../shared/components/customPublicProfile/CustomPublicProfile';

const theme = jfTheme;

export const PublicRoutes = () => {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display='flex' flexDirection='column' minHeight='100vh'>
          <PublicAppBar window={() => window} />
          <Box flexGrow={1} flexShrink={1}>
            <Switch>
              <Route path='/publicprofile/:profileSlug' component={CustomPublicProfile} />
              <Route path='*' component={NotFoundPage} />
            </Switch>
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
