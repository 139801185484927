import { useCallback, useMemo, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Menu,
  Divider,
  List,
  ListItem,
  IconButton
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Logo } from '../../../common/logo/Logo';
import {
  MenuLabel,
  NavMenuLink,
  NavbarBox,
  StyledDivider,
  SignInSignUpLink,
  StyledDrawer,
  DrawerMenuLink
} from './PublicAppBar.styles';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import MenuIcon from '@mui/icons-material/Menu';

type INavItem = {
  title: string;
  pathname: string;
  items?: Array<{ title: string; link: string }>;
};

type iPublicAppBarProps = {
  window: () => Window;
};

export const PublicAppBar = ({ window }: iPublicAppBarProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [subMenuIndex, setSubMenuIndex] = useState<number | null>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;
  const mobileDisplay = { display : { xs: 'none', sm: 'flex' }};

  const handleMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLElement>, index: number) => {
      setAnchorEl(event.currentTarget);
      setSubMenuIndex(index);
    },
    []
  );

  const handleMouseLeave = useCallback(() => {
    setAnchorEl(null);
    setSubMenuIndex(null);
  }, []);

  const NAV_ITEMS: INavItem[] = useMemo(()  => ([
    {
      title: 'How It Works',
      pathname: 'https://justfund.us/how-it-works/',
      items: [
        { title: 'For Applicants', link: 'https://justfund.us/how-it-works/for-grantseekers/' },
        { title: 'For Grantmakers', link: 'https://justfund.us/how-it-works/for-grantmakers/' },
        { title: 'Common Application', link: 'https://justfund.us/common-application/' }
      ]
    },
    {
      title: 'About',
      pathname: 'https://justfund.us/about/',
      items: [
        { title: 'Our Approach', link: 'https://justfund.us/about/#ourapproach' },
        { title: 'Our Values', link: 'https://justfund.us/about/values/' },
        { title: 'Our Team', link: 'https://justfund.us/about/our-team/' },
        { title: 'Our Careers', link: 'https://boards.greenhouse.io/justfund' }
      ]
    },
    {
      title: 'Services',
      pathname: 'https://justfund.us/services/'
    },
    {
      title: 'News & Events',
      pathname: 'https://justfund.us/news-events/',
      items: [
        { title: 'Blog', link: 'https://justfund.us/news-events/' },
        { title: 'Webinars', link: 'https://learn.justfund.us/grantseeker-training' },
        { title: 'Contact Us', link: 'https://justfund.us/contact-us/' },
      ]
    },
  ]), []);

  const renderNavItems = useCallback(() => {
    return (
      <Box display='flex' flexDirection='row'>
        { NAV_ITEMS.map(({ title, pathname, items }, index) => {
          return (
            <NavMenuLink
              key={`nav-menu-${index}`}
              to={{ pathname }}
              target='_blank'
              rel="noopener noreferrer"
              onMouseEnter={ 
                (event) => handleMouseEnter(event, index)
              }
              onMouseLeave={handleMouseLeave}
              replace
            >
              <MenuLabel as='span'>{title}</MenuLabel>
              {items && (
              <Menu
                anchorEl={anchorEl}
                open={subMenuIndex === index}
                onClose={handleMouseLeave}
                keepMounted
              >
                { items.map(({ link, title }, subIndex) => (
                  <NavMenuLink
                    key={`nav-submenu-${subIndex}`}
                    to={{ pathname: link }}
                    target='_blank'
                    rel="noopener noreferrer"
                    onClick={handleMouseLeave}
                    replace
                  >
                    <MenuLabel as='span'>{title}</MenuLabel>
                  </NavMenuLink>
                ))}
              </Menu>
              )}
            </NavMenuLink>
          );
        })}
      </Box>
    )
  }, [NAV_ITEMS, subMenuIndex, anchorEl, handleMouseEnter, handleMouseLeave]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const renderDrawer = useCallback(() => (
    <Box onClick={handleDrawerToggle}>
      <Box py={2} textAlign='center'>
        <Link
          to={{ pathname: 'https://justfund.us/' }}
          target='_top'
          rel="noopener noreferrer"
          replace
        >
          <Logo img='/images/justfund.svg' mlf={20}/>
        </Link>
      </Box>
      <Divider />
      <List>
      { NAV_ITEMS.map(({ title, pathname, items }, index) => {
        return (
          <>
            <ListItem key={`nav-menu-${index}`} disablePadding>
              <DrawerMenuLink
                to={{ pathname }} 
                target='_top'
                rel= "noopener noreferrer"
                replace
                isBold
              >
                {title}
              </DrawerMenuLink>
            </ListItem>
            {items && (
              items.map(({ link, title }, subIndex) => (
                <ListItem key={`nav-submenu-${subIndex}`} disablePadding>
                  <DrawerMenuLink
                    to={{ pathname: link }} 
                    target='_top'
                    rel= "noopener noreferrer"
                    replace
                  >
                    {title}
                  </DrawerMenuLink>
                </ListItem>
              ))
            )}
            <Divider />
          </>
        );
      })}
      </List>
      <Box display='flex' flexDirection='column' gap='10px' alignItems='center' paddingY='20px'>
        <SignInSignUpLink 
          to={{ pathname: 'https://portal.justfund.us/justfund/signin' }} 
          target='_top'
          rel= "noopener noreferrer"
        >
          <LoginIcon /> Sign In
        </SignInSignUpLink>
        <Divider sx={{ width: "80%" }} />
        <SignInSignUpLink
          to={{ pathname: 'https://justfund.us/sign-up-2' }} 
          target='_top'
          rel= "noopener noreferrer"
          replace
        >
          <PersonAddAltIcon/> Sign Up
        </SignInSignUpLink>
      </Box>
    </Box>
  ), [NAV_ITEMS]);

  return (
    <>
      <AppBar position="sticky" component='nav'>
        <Toolbar 
          sx={{
            maxWidth: '1200px',
            margin: 'auto',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Link
            to={{ pathname: 'https://justfund.us/' }}
            target='_top'
            rel="noopener noreferrer" 
            replace
            style={{ marginTop: '10px' }}
          >
            <Logo img='/images/justfund.svg' />
          </Link>
          <NavbarBox sx={{ ...mobileDisplay }}>
            {renderNavItems()}
          </NavbarBox>
          
          <Box flexDirection='row' display='flex' sx={{ ...mobileDisplay }}>
            <SignInSignUpLink 
              to={{ pathname: 'https://portal.justfund.us/justfund/signin' }} 
              target='_top'
              rel= "noopener noreferrer"
            >
              <LoginIcon /> Sign In
            </SignInSignUpLink>
            <StyledDivider orientation="vertical" variant='middle' flexItem />
            <SignInSignUpLink
              to={{ pathname: 'https://justfund.us/sign-up-2' }} 
              target='_top'
              rel= "noopener noreferrer"
              replace
            >
              <PersonAddAltIcon/> Sign Up
            </SignInSignUpLink>
          </Box>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none', flexGrow: 1, justifyContent: 'end' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav>
        <StyledDrawer
          container={container}
          variant="temporary"
          anchor='right'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none'},
          }}
        >
          {renderDrawer()}
        </StyledDrawer>
      </nav>
    </>
  );
};
