import { AxiosRequestConfig } from "axios";
import { JFDataGridQueryResponse } from "../../components/tables/datagrid/JFDataGrid";
import { IBloatedGrantee, ICommitment, ICommitmentsQuery, IProposal } from "../../interfaces";
import { IBloatedFunder } from "../../interfaces/IFunder";
import { api } from "./api.service";
import { IManageFundersRow } from "./funder-api.service";

// Interface used for any findAndCountAll query - leave rows: at any[], so it can be used in multiple places. 
interface IAdminTableProps {
    count: number;
    rows: any[];
}
export class AdminAPIService {
    private static baseRoute = '/api/admin'

    // IProposal interface might not work here, might need a partial or a completely different interface
    public static async proposals( limit?: number, offset?: number, order?: number,): Promise<IAdminTableProps> {
      const res = await api.get(`${this.baseRoute}/proposals/`, { params: {  limit, offset, order } })
      return res.data as IAdminTableProps;
    }

    // TODO - type this.
    public static async getUsersReport(options: AxiosRequestConfig<any>,limit?: number, offset?: number, order?: [], where?: any): Promise<IAdminTableProps> {
      const res = await api.get(`${this.baseRoute}/users`, { params: {  limit, offset, order, where } })
      return res.data as IAdminTableProps;
    }

    public static async getGranteeReport(options: AxiosRequestConfig<any>,limit?: number, offset?: number, order?: [], where?: any): Promise<IBloatedGrantee[]> {
      const res = await api.get(`${this.baseRoute}/granteeReport`, { params: {  limit, offset, order, where } })
      return res.data as IBloatedGrantee[];
    }

    public static async getById(id: number): Promise<IProposal> {
      const res = await api.get(`${this.baseRoute}/proposals/${id}`)
      return res.data as IProposal;
    }

    public static async queryEvents(body: ICommitmentsQuery, options: AxiosRequestConfig = {}): Promise<JFDataGridQueryResponse<ICommitment[]>> {
      const res = await api.post(`${this.baseRoute}/events/query`, body, options);
      return res.data as JFDataGridQueryResponse<ICommitment[]>;
    }

    public static async queryCommitments(body: ICommitmentsQuery, options: AxiosRequestConfig = {}): Promise<JFDataGridQueryResponse<ICommitment[]>> {
      const res = await api.post(`${this.baseRoute}/commitments/query`, body, options);
      return res.data as JFDataGridQueryResponse<ICommitment[]>;
    }

    public static async queryProposals(body: ICommitmentsQuery, options: AxiosRequestConfig = {}): Promise<JFDataGridQueryResponse<ICommitment[]>> {
      const res = await api.post(`${this.baseRoute}/proposals/query`, body, options);
      return res.data as JFDataGridQueryResponse<ICommitment[]>;
    }

    public static async getGrantCycleList(): Promise<any[]> {
      const res = await api.get(`${this.baseRoute}/grant-cycles`)
      return res.data;
    };

    public static async getFunderReport(options: AxiosRequestConfig) : Promise<IBloatedFunder[]> {
      const res = await api.get(`${this.baseRoute}/funders/report`);
      return res.data as IBloatedFunder[];
    };

    public static async getFunderList(query = {}): Promise<IManageFundersRow[]> {
      const res = await api.get(`${this.baseRoute}/funders`, { params: query }).then(response => {
        return response;
      });
      return res.data as IManageFundersRow[];
    }

    public static async adminFunderCSV(params: {}) {
      const res = await api.get(`${this.baseRoute}/csv/admin-funders`, { params: params });
      return res.data;
    };

    public static async adminCommitCSV(params: {}) {
      const res = await api.get(`${this.baseRoute}/csv/admin-commitments`, { params: params });
      return res.data;
    };
}