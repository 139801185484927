import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ScrollToTop } from '../../../shared/util/scrollToTop';
import { JFForgotPassword, RequiredResetPassword, SignIn } from '../signin';
import { NewPassword } from '../signin/NewPasswordForm'
import { Info } from '../info';
import { PrivacyNotice, TermsOfUse } from '../notices';
import { Nav } from './Nav';
import { Footer } from '../../../shared/components/Footer';
import { jfTheme } from '../../../styles/jf.theme'
import { DynamicRedirect } from '../redirect/DynamicRedirect';

const theme = jfTheme;

export const App = () => {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* TODO: Have this pull from the shared/utils file */}
        <ScrollToTop />
        <CssBaseline />
        <Box display='flex' flexDirection='column' minHeight='100vh'>
          <Nav />
          <Box flexGrow={1} flexShrink={1}>
            <Switch>
              <Route exact path='/' component={SignIn} />
              <Route exact path='/:slug' component={DynamicRedirect} />
              <Route exact path='/justfund/howitworks' component={Info} />
              <Route exact path='/justfund/signin' component={SignIn} />
              <Route exact path='/justfund/forgot-password' component={JFForgotPassword} />
              <Route exact path='/justfund/password/:resetType/:token' component={NewPassword} />
              <Route exact path='/justfund/reset-password-required/:id' component={RequiredResetPassword} />
              <Route exact path='/justfund/terms' component={TermsOfUse} />
              <Route exact path='/justfund/privacy' component={PrivacyNotice} />
            </Switch>
          </Box>
          <Footer />
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

//  <Route exact path='/f/grants/qualify-final/:stage/:cycle' component={GrantQualifyFinalList} />
