import { createTheme } from '@mui/material/styles';
import type { } from '@mui/x-date-pickers/themeAugmentation';

export const jfTheme = createTheme(({
  palette: {
    primary: {
      main: '#091b30',
      contrastText: '#fdfbe4',
    },
    secondary: {
      main: '#e61436',
      contrastText: '#fdfbe4',
      light: '#0A61B0'
    },
    background: {
      paper: '#f7f7f7',
      default: '#f7f7f7',
    },
    warning: {
      main: '#e61436',
      contrastText: '#fdfbe4',
    },
    info: {
      main: '#5d5f73',
    },
  },
  typography: {
    fontFamily: 'Proxima Nova, sans-serif',
    fontSize: 20,
    h1: {
      fontWeight: 900,
      fontSize: 54,
    },
    h2: {
      fontWeight: 900,
      fontSize: 40,
    },
    h3: {
      fontWeight: 900,
      fontSize: 30,
    },
    h4: {
      fontWeight: 700,
      fontSize: 26,
    },
    h5: {
      fontWeight: 600,
      fontSize: 22,
    },
    h6: {
      fontWeight: 600,
      fontSize: 16,
    },
    body1: {
      fontSize: 20,
    },
    body2: {
      fontSize: 16,
    },
    caption: {
      fontSize: 14,
    },
    button: {
      fontSize: '1rem',
      fontWeight: 700,
    },

  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          backgroundColor: '#ffffff',
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#000000',
          fontSize: '16px'
        },
        standard: {
          position: 'relative',
          fontFamily: '"Proxima Nova", sans-serif',
          fontWeight: 600,
          fontSize: 16,
          lineHeight: 1.6,
          transform: 'unset'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          backgroundColor: '#ffffff',
          '&:focus': {
            backgroundColor: '#fcffff',
          },
        },
        multiline: {
          backgroundColor: '#ffffff',
          '&:hover': {
            backgroundColor: '#ffffff',
          },
          '&:focus': {
            backgroundColor: '#fcffff',
          },
        },
        adornedStart: {
          backgroundColor: '#fafafa',
        },
        adornedEnd: {
          backgroundColor: '#fafafa',
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: '#fcffff',
          },
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#3E86C7'
        },
        outlined: {
          border: '2px solid black',
          '&:hover': {
            border: '2px solid black',
          }
        },
        containedPrimary: {
          backgroundColor: '#3E86C7'
        },
        root: {
          '&.MuiButton-containedPrimary': {
            backgroundColor: '#3E86C7'
          },
          '&.MuiButton-containedSecondary:hover': {
            backgroundColor: '#24659E'
          },
          '&.MuiButton-outlinedSecondary:hover': {
            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            borderColor: '#24659E',
            color: '#4288C5',
            backgroundColor: 'inherit'
          }
        },
        containedSecondary: {
          backgroundColor: '#4288C5',
          textTransform: "none",
          borderRadius: "50px",
          boxShadow: 'none',
        },
        outlinedSecondary: {
          borderRadius: "50px",
          boxShadow: 'none',
          textTransform: "none",
          borderColor: '#4288C5',
          color: '#4288C5'
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&$completed': {
            color: '#754482',
          },
          '&$active': {
            color: '#fdab3e',
          },
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#ffffff',
        },
        root: {
          '&.MuiAppBar': { 
            backgroundColor: '#fff'
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      }
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: "Proxima Nova, sans-serif"
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        fontFamily: "Proxima Nova, sans-serif"
      }
    }
  },
}))
