import { Container, styled } from '@mui/material';
import { JFContainerProps } from './JFContainer.types';

export const JFContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'fullScreen'
})<JFContainerProps>(({ theme, fullScreen = false }) => {
  return fullScreen
    ? {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100% !important',
        padding: '50px 30px !important',
        [theme.breakpoints.down('md')]: {
          padding: '30px 20px!important'
        }
      }
    : {
        padding: '48px 16px !important'
      };
});
