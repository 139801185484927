import React, { Suspense, useEffect } from 'react';
import './App.css';
import { App as Start } from './start/components/app/App';
import { App as Public } from './public/components/app/App';
import { PublicRoutes } from './public/components/publicRoutes/PublicRoutes';
import { jfTheme } from './styles/jf.theme';
import { Notification, NotificationProvider } from './shared/components/modals/Notification';
import { AlertComponent } from './shared/components/modals/Alert';
import { StyledEngineProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { Route, Switch, useLocation } from 'react-router-dom';
import { LoadingPage } from './shared/components/LoadingPage';
import { createTheme as createMUITheme, ThemeProvider as MUIThemeProvider, Theme } from "@mui/material";
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { HubspotService } from './shared/services';
import { UserRegister } from './admin/manage/user-logins/UserRegister';
import UserProvider from './group/providers/UserProvider';
import AdminProvider from './admin/providers/adminProvider.provider';
import FunderProvider from './funder/providers/UserProvider';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

// Key for DataGridPro
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATAGRID_LICENSE as string);

const Group = React.lazy(() => import('./group/components/app/App'));
const Funder = React.lazy(() => import('./funder/components/app/App'));
const Admin = React.lazy(() => import('./admin/Admin'));

// build theme
// const theme = createTheme();
const MUITheme = createMUITheme(jfTheme);

// https://cssinjs.org/jss-api/?v=v10.9.0#generate-your-class-names
const generateClassName = () => {
  const randomInt = () => crypto.getRandomValues(new Uint16Array(1))[0];
  return (rule: any, sheet: any) => `JF-${rule.key}-${randomInt()}`;
}

function App() {
  const location = useLocation();

  useEffect(() => {
    HubspotService.recordPageView();
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  return (
    <StylesProvider generateClassName={generateClassName()}>
      <StyledEngineProvider injectFirst={true}>
        {/* Required per https://mui.com/guides/interoperability/#css-injection-order-2 */}
        <MUIThemeProvider theme={MUITheme}>
          {/* <CssBaseline />  */}
          {/* <ThemeProvider theme={theme}> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <NotificationProvider>
              <AlertComponent />
              <Switch>
                <Route path="/o">
                  <UserProvider>
                    <Suspense fallback={
                      <LoadingPage
                        message={'Loading...'}
                        error={null}
                      />
                    }>
                      <Group />
                    </Suspense>
                  </UserProvider>
                </Route>
                <Route path="/admin">
                  <AdminProvider>
                    <Suspense fallback={<LoadingPage
                      message={'Loading...'}
                      error={null}
                    />}>
                      <Admin />
                    </Suspense>
                  </AdminProvider>
                </Route>
                <Route path="/f">
                  <FunderProvider>
                    <Suspense fallback={<LoadingPage
                      message={'Loading...'}
                      error={null}
                    />}>
                      <Funder />
                    </Suspense>
                  </FunderProvider>
                </Route>
                <Route path="/p">
                  <Public />
                </Route>
                <Route path="/publicprofile/:profileid">
                  <PublicRoutes />
                </Route>
                <Route path='/register/:id'>
                  <UserRegister />
                </Route>
                <Route path="/">
                  <Start />
                </Route>
              </Switch>
              <Notification />
            </NotificationProvider>
          </LocalizationProvider>
          {/* </ThemeProvider> */}
        </MUIThemeProvider>
      </StyledEngineProvider >
    </StylesProvider>
  );
}

export default App;
