// Notification.tsx
import { createContext, FC, useContext, useReducer } from 'react';
import { NotificationStatus } from '../../enums/notification-status.enum';
import { NotificationReason } from '../../enums/global/notification-reason.enum';
import PopupUpdateRender from './PopupUpdateRender';
import NotificationRender from './NotificationRender';

export interface INotificationState {
  id?: number;
  open: boolean;
  onClose: (val: any, reason?: string) => void;
  title: string;
  message?: string | JSX.Element;
  component?: any;
  removeCancelBtn?: boolean;
  customBtnText?: string;
  customBtnFunc?: any;
  customComponent?: any;
  type: 'ERROR' | 'NOTIFICATION';
  toggleNotification?: (val: boolean) => void;
  reason?: string;
  renderType?: 'popupUpdate' | 'notification';
}

export interface INotificationReducer {
  type: NotificationStatus.UPDATE | NotificationStatus.RESET;
  data: Partial<INotificationState>;
  renderType?: 'popupUpdate' | 'notification';
}

interface IShowAlert {
  title: string;
  message?: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
}

interface IMessagingContext {
  notificationState: INotificationState;
  notificationDispatch: (val: INotificationReducer) => void;
  alertState: IShowAlertState;
  alertDispatch: (val: IAlertReducer) => void;
  resetAlert: () => void;
  resetNotification: () => void;
}

interface IShowAlertState extends IShowAlert {
  showAlert: (val: IShowAlert) => void;
  showError: (val: Omit<IShowAlert, 'severity'>) => void;
  showWarning: (val: Omit<IShowAlert, 'severity'>) => void;
  showInfo: (val: Omit<IShowAlert, 'severity'>) => void;
  showSuccess: (val: Omit<IShowAlert, 'severity'>) => void;
}

interface IAlertReducer {
  type: 'UPDATE' | 'RESET';
  data: Partial<IShowAlert>;
}

const defaultNotificationState: INotificationState = {
  open: false,
  title: 'Information',
  type: 'NOTIFICATION',
  onClose: (val) => {},
  toggleNotification: (val: boolean) => {}
};

const defaultAlertState = {
  title: '',
  message: ''
};

const MessagingContext = createContext<IMessagingContext>({
  notificationState: {} as INotificationState,
  notificationDispatch: (val: INotificationReducer) => {},
  alertState: {} as IShowAlertState,
  alertDispatch: (val: IAlertReducer) => {},
  resetAlert: () => {},
  resetNotification: () => {}
});

const notificationReducer = (
  state: INotificationState,
  { type, data, renderType }: INotificationReducer
) => {
  switch (type) {
    case NotificationStatus.UPDATE:
      return { ...state, ...data, renderType };
    case NotificationStatus.RESET:
      return defaultNotificationState;
    default:
      throw new Error(`Type ${type} in reducer not defined`);
  }
};

const alertReducer = (state: IShowAlert, { type, data }: IAlertReducer) => {
  switch (type) {
    case 'UPDATE':
      return { ...state, ...data };
    case 'RESET':
      return defaultAlertState;
    default:
      throw new Error(`Type ${type} in reducer is not defined `);
  }
};

export const NotificationProvider: FC = ({ children }: any) => {
  const [_notificationState, notificationDispatch] = useReducer(
    notificationReducer,
    defaultNotificationState
  );

  const notificationState: INotificationState = {
    ..._notificationState,
    toggleNotification: (open = false) =>
      notificationDispatch({ type: NotificationStatus.UPDATE, data: { open } })
  };

  const [_alertState, alertDispatch] = useReducer(
    alertReducer,
    defaultAlertState
  );

  const alertState = {
    ..._alertState,
    showAlert: ({ title, message, severity }: IShowAlert) =>
      alertDispatch({ type: 'UPDATE', data: { title, message, severity } }),
    showError: ({ title, message }: Omit<IShowAlert, 'severity'>) =>
      alertDispatch({
        type: 'UPDATE',
        data: { title, message, severity: 'error' }
      }),
    showWarning: ({ title, message }: Omit<IShowAlert, 'severity'>) =>
      alertDispatch({
        type: 'UPDATE',
        data: { title, message, severity: 'warning' }
      }),
    showInfo: ({ title, message }: Omit<IShowAlert, 'severity'>) =>
      alertDispatch({
        type: 'UPDATE',
        data: { title, message, severity: 'info' }
      }),
    showSuccess: ({ title, message }: Omit<IShowAlert, 'severity'>) =>
      alertDispatch({
        type: 'UPDATE',
        data: { title, message, severity: 'success' }
      })
  };

  const resetAlert = () => alertDispatch({ type: 'RESET', data: {} });
  const resetNotification = () =>
    notificationDispatch({ type: NotificationStatus.RESET, data: {} });

  const messagingState: IMessagingContext = {
    notificationState,
    notificationDispatch,
    alertState,
    alertDispatch,
    resetAlert,
    resetNotification
  };
  return (
    <MessagingContext.Provider value={messagingState}>
      {children}
    </MessagingContext.Provider>
  );
};

export const useMessagingContext = () => useContext(MessagingContext);

/**
 *
 * @description
 * This should only be imported one time into the application, all other functionality
 * is handled by useContext
 */

export const Notification: FC = () => {
  const { notificationState, resetNotification } = useMessagingContext();
  const { open, reason, renderType } = notificationState;

  const handleClose = (val: boolean, reason?: string) => {
    if (!!notificationState.customBtnFunc && val && notificationState.id) {
      notificationState.customBtnFunc(notificationState.id);
      notificationState.onClose(val, reason);
      resetNotification();
    } else if (!!notificationState.customBtnFunc && val) {
      notificationState.customBtnFunc();
      notificationState.onClose(val, reason);
      resetNotification();
    } else {
      if (reason && reason === NotificationReason.BackdropClick) {
        return;
      } else {
        notificationState.onClose(val, reason);
      }
      resetNotification();
    }
  };

  return renderType === 'popupUpdate' ? (
    <PopupUpdateRender
      notificationState={notificationState}
      handleClose={handleClose}
    />
  ) : (
    <NotificationRender
      notificationState={notificationState}
      handleClose={handleClose}
    />
  );
};
