import { IListResults } from "../../interfaces/IGenerics.interfaces";
import { IRfp } from "../../interfaces/IRfp.interfaces";
import { api } from "./api.service";
import { IInvitation } from "../../interfaces/IInvitation.interfaces";
import { AxiosRequestConfig } from "axios";
import { RFPStatus } from "../../enums/rfp-status";
import { endOfToday, startOfToday } from "date-fns";

type listCurrentParams = {
  rolling: boolean | null,
  granteeId: number | string | undefined,
  status?: RFPStatus,
}

export class RfpService {

  private static baseRoute = "/api/rfp"

  public static async list(): Promise<IListResults<IRfp>> {
    const res = await api.get(`${this.baseRoute}/list`);
    return res.data as IListResults<IRfp>;
  }

  public static async listCurrent(params: listCurrentParams, signal?: AxiosRequestConfig): Promise<IListResults<IRfp>> {
    const res = await api.get(`${this.baseRoute}/list-current`, { params });
    return res.data as IListResults<IRfp>;
  }

  public static async inviteOnly(granteeId: string): Promise<IListResults<IRfp>> {
    const endOfDay = endOfToday();
    const startOfDay = startOfToday();
    const res = await api.get(`${this.baseRoute}/list`, { params: { invite: granteeId, announcement_date: startOfDay.toISOString(), deadline_date: endOfDay.toISOString(), status: RFPStatus.APPROVED } });
    return res.data as IListResults<IRfp>;
  }

  public static async getAllFunderRfps(signal?: AxiosRequestConfig): Promise<IRfp[]> {
    const res = await api.get(`${this.baseRoute}/funder`);
    return res.data as IRfp[];
  }

  public static async getById(id: number, signal?: AxiosRequestConfig): Promise<IRfp> {
    const res = await api.get(`${this.baseRoute}/${id}`);
    return res.data as IRfp;
  }

  public static async update(cycleId: number, rfp: Partial<IRfp>): Promise<IRfp> {
    const res = await api.patch(`${this.baseRoute}/${cycleId}`, {
      ...rfp
    });
    return res.data as IRfp;
  }

  public static async create(rfp: any): Promise<IRfp> {
    const res = await api.post(`${this.baseRoute}`, {
      ...rfp
    });
    return res.data as IRfp;
  }

  public static async duplicate(rfp: { id: number, funder_id: number }): Promise<IRfp> {
    const res = await api.post(`${this.baseRoute}/duplicate`, {
      ...rfp
    });
    return res.data as IRfp;
  }

  public static async createInvitation(grantCycleId: number, granteeId: number): Promise<IInvitation> {
    const payload = {
      grantee_id: granteeId,
      rfp_id: grantCycleId
    }
    const res = await api.post(`${this.baseRoute}/invitations`, payload);

    return res.data as IInvitation;
  }

  public static async delete(id: number): Promise<IRfp> {
    const res = await api.delete(`${this.baseRoute}/${id}`);
    return res.data as IRfp;
  }

  public static async deleteInvitation(invitationId: number): Promise<{ deletedInvite: boolean }> {
    const res = await api.delete(`${this.baseRoute}/invitation/${invitationId}`);
    return res.data as { deletedInvite: boolean };
  }

  public static async getByFunderGroupId(id: number): Promise<IRfp[]> {
    const res = await api.get(`${this.baseRoute}/funder/${id}`);
    return res.data as IRfp[];
  }

  public static async getRfpForScoring(): Promise<IRfp[]> {
    const res = await api.get(`${this.baseRoute}/score`);
    return res.data as IRfp[];
  }

  public static async confirmEligibility({ granteeId, id }: { granteeId: number, id: number }): Promise<IRfp> {
    const res = await api.post(`${this.baseRoute}/${id}/confirm-eligibility`, {
      granteeId
    });
    return res.data as IRfp; // TBD
  }
}
