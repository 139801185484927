import { Box, Grid } from '@mui/material';
import JFModal from '../../../../../common/components/JFModal';
import { useEffect, useState } from 'react';
import { FunderService } from '../../../../services/api-service/funder-api.service';
import { ProfileCard } from '../../../profileCard/ProfileCard';

import InfiniteScroll from 'react-infinite-scroll-component';
import { EProfileType } from '../../../../enums/profile.enum';
import { IProfile } from '../../../../interfaces/IProfile';
import { Loading } from '../../../legacy-ui';
import { PublicAPIService } from '../../../../services/api-service/public-api.service';

interface IFundedOrganizationsModal {
  isOpen: boolean;
  handleClose: () => void;
  funderId: number;
  isAdmin: boolean;
  profileSlug?: string;
}

const FundedOrganizationsModal = ({ isOpen, handleClose, funderId, isAdmin, profileSlug }: IFundedOrganizationsModal) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [fundedOrganizations, setFundedOrganizations] = useState<IProfile[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const limit = 15;

  useEffect(() => {
    getOrganizations();
  }, []);

  const getOrganizations = async () => {
    const data = profileSlug  ?
      await PublicAPIService.getFundedTabBySlug(profileSlug, {
        limit,
        offset
      }) :
      await FunderService.getFundedTab(funderId, {
        limit,
        offset
      });

    if (data) {
      setFundedOrganizations([...fundedOrganizations, ...data]);
      setOffset(offset + limit);

      if (data.length < limit) {
        setHasMore(false);
      }
    }
    if (loading) {
      setLoading(false);
    }
  };

  return (
    <JFModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Funded Organizations"
    >
      {loading ? (
        <Loading />
      ) : (
        <Box sx={{ height: '75vh', overflow: 'auto' }} id="scrollableBox">
          <InfiniteScroll
            dataLength={fundedOrganizations.length}
            next={getOrganizations}
            hasMore={hasMore}
            loader={<Loading />}
            scrollableTarget="scrollableBox"
          >
            <Grid container spacing={3}>
              {fundedOrganizations.map((grantee) => {
                return (
                  <Grid item key={grantee!.id} md={4} sm={12} xs={12}>
                    <ProfileCard
                      profile={grantee!}
                      profileType={!!grantee.funder ? EProfileType.FUNDER : EProfileType.GRANTEE}
                      isAdmin={isAdmin}
                      showFunders={false}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>
        </Box>
      )}
    </JFModal>
  );
};

export default FundedOrganizationsModal;